<template>
  <div>
    <vb-forgot-password />
  </div>
</template>
<script>
import VbForgotPassword from '@/@vb/components/Auth/ForgotPassword'
export default {
  components: {
    VbForgotPassword,
  },
}
</script>
